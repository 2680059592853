.mm-menu{
    background: $secondary-color;
}
.mm-navbar .mm-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #ffffff !important;
    font-size: 18px;
}
.mm-listview, .mm-listview > li{
    list-style: none;
    display: block;
    padding: 0;
    margin: 0;
}
.mm-menu .mm-listview > li a:not(.mm-next){
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    color: #ffffff;
    border-bottom: 1px solid white;
    font-size: 18px;
}
.mm-listview > li > a, .mm-listview > li > span{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    padding: 16px 10px 16px 20px;
    margin: 0;
}
.mm-listview>li, .mm-listview>li:after, .mm-listview>li .mm-next, .mm-listview>li .mm-next:before{
    border-color: #ffffff;
}
.mm-navbar{
    border-color: #ffffff;
}
.mm-menu .mm-listview>li .mm-next:after, .mm-menu .mm-listview>li .mm-arrow:after{
    border-color: #ffffff;
}