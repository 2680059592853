body{
    line-height: 1.8;
}
/*Meetings*/
.meetingsSubscriptionFlow{
    //stupid rule but necessary cuz hardcore class meeting form..
    .text-right{
        text-align: left !important;
    }
}

/*dangerous grid overruling*/
.column, .columns{
    padding-left: 0;
    padding-right: 0;
}

@mixin medium-basic {
    .column, .columns{
        padding-left: 1rem;
        padding-right: 1rem;
    }
}