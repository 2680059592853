#CCIPlaceHolder_Footer{
    background-color: $primary-color;
    padding-top: 40px;
    padding-bottom: 80px;  
}
footer {    
    color: #fff;
    display: flex;
    flex-flow: row wrap;    
    max-width: 75rem;
    padding: 0 1rem;
    figure{
        margin: 0;
        padding: 0;
        img{
            height: auto;
            width: 100%;
        }
    }
    .content{        
        padding-top: 80px;
        padding-bottom: 80px;
    }
    .column{
        padding-left: 0;
        padding-right: 0;
        float: none;
        p{
            margin-top: 0;
        }
    }
    h3 {
        color: #fff;
        margin-bottom: 0;
        margin-top: 0;
        line-height: 1.4;
    }
    a {
        color: #fff;
        text-decoration: none;
        padding-right: 1em;
        display: block;
        &:hover {
            color: #bd8b40;
        }
    }
    .column {
        width: 100%;
    }
    .column:nth-child(1) {
        width: 100%;
        order: 1;
    }
    .column:nth-child(2) {
        width: 100%;
        order: 0;
    }

}

@mixin medium-footer {
    #CCIPlaceHolder_Footer{
        padding-top: 80px;
    }
    footer {
        padding: 0 1rem;
        .column:nth-child(2) {
            flex: 1 1 calc(75% - 110px);            
        }
        .column:nth-child(1) {
           width: calc(25%);
           margin-right: 110px;
           order: 0;
        }
        .column.logo{
            display: flex;
            justify-content: flex-end;
            order: 1;
        }
    }
}

@mixin large-footer {
    footer{
        padding: 0;
    }
}