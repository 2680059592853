header {
    width: 100%;
    background: #000000;
    position: relative;
    line-height: normal;
    .util-bar {
        background: $primary-color;     
        display: flex;
        align-items: center;
        padding: 0;
    }
    .logo {
        padding-top: 10px;
        padding-left: 20px;
        padding-bottom: 4px;
        height: auto;
        width: 130px;
        margin: 0; 
    }
    .topmenu {
        height: 2.625rem;
        display: flex;
        flex-flow: row wrap;
        max-width: 80rem;
        padding: 0 1rem;
        width: 100%;
        margin: 0 auto;
        justify-content: flex-end;
        align-items: baseline;
        a{
            color: $secondary-color;
            text-decoration: none;
        }
        #fontSize{
            display: none;
        }
        .search {
            display: none;
         }
        p{
            margin: 10px 0 0 0;
            line-height: normal;
        }
    }
    .servicemenu{
        p{
            margin:0;
        }        
        a{
            color: #ffffff;
            text-decoration: none;
            padding-right: 1rem;
        }
    }
    .mobile-menu {
        position: absolute;
        right: 1.25rem;
        top: 3.5rem;
        padding: 1.25rem;
        cursor: pointer;
        z-index:1;
        &:after {
            content: "\f0c9";
            position: absolute;
            width: 30px;
            top: 0.75rem;
            right: .75rem;
            font-family: "Font Awesome 5 Free"; 
            font-weight: 900;
            font-size: 40px;
            color: var(--primary);
        }
    }
    .navigation-header {
        padding: 0.2rem 0;
        position: relative;
        z-index: 1;
        .menu {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            background-color: $secondary-color;

            padding: 0;
            margin: 0;
            &.active {
                .item {
                    display: block;
                }
            }
        }
        .item {
            width: 100%;
            text-align: left;
            display: none;
            padding: 0 40px;
            border-bottom: 1px solid #fff;
            &:last-child{
                border-bottom:none;
            }
            a {
                text-decoration: none;
                font-size: 1.25rem;
                font-weight: 500;
                font-family: $main-font;
                letter-spacing: 0;
                color: #ffffff;
                &:hover{
                    color: $primary-color;  
                }
            }
            &.active{
                a{
                    color: $primary-color;  
                }
            }
        }
    }
    ul, ol{
        line-height: 1.8;
    }
}
#mobileNav{
    display: none;
    &.mm-menu.mm-current{
        display: block;
    }
}

@mixin medium-header {
    header {
        .logo {
            padding-bottom: 0;  
            padding-left: 40px;   
        }
        .topmenu{
            padding: 0 2.5rem;
            #fontSize{
                line-height: 0;
                display: flex;
                flex-direction: row;
                align-items: baseline;
                margin: 10px 0 0 0;
            }
            #fontSize span {
                color: var(--secondary);
                padding: 0 6px 0 0;
                margin-top: -2px;
                cursor: pointer;
            }
            .search {
                padding: 8px 0 0 0;
                display: flex;
                .formulier_velden.mini_velden {
                    width: 15rem;
                }
                .fouten {
                    color: #fff;
                    font-size: .875rem;
                }
                input[type="submit"] {
                    display: none;
                }
                input {
                    border: none;
                    font-size: 1rem;
                    padding: .325rem;
                    margin-right: 1rem;
                    width: 100%;
                    &::placeholder {
                        font-size: .875rem;
                        color: #999;
                    }
                }
                .content{
                    padding: 0;
                    max-width: unset;
                    margin: auto;
                    flex-flow: unset;
                    display: block;
                }
            }
            p{
                margin: 0;
            }
        }
        .mobile-menu{
            right: 2rem;
        }
    }
}

@mixin large-header {
    header {
        margin-bottom: 4.375rem;
        .mobile-menu {
            display: none;
        }
        .navigation-header {
            display: flex;
            flex-flow: row wrap;
            max-width: 80rem;
            width: 100%;
            margin: 0 auto;
            padding: 0 2.5rem 0.875rem 2.5rem;
            overflow: visible;
            .menu{
                background: none;
                /*Level 2*/
                .level-2{
                    display: none;
                    width: auto;
                    overflow: visible;
                    clip: auto;
                    position: absolute;
                    background: $secondary-color;
                    top: 65px;
                    z-index: 9999;                    
                    min-width: 200px;
                    margin: 0;
                    padding: 0;
                    li{
                        padding: 7px 24px;
                        border-bottom: 1px solid $primary-color;
                        &.active{                            
                            a{
                                color: #ffffff;
                            }
                        }                        
                    }
                    a{
                        font-size: 1rem;
                        color: $primary-color;
                    }
                }
                .has-dropdown:hover{
                    .level-2{
                    display: flex;
                    flex-direction: column;
                    }
                }
            }            
            nav {
                display: flex;
                flex: 1;
                justify-content: flex-end;
            }
            .item {
                display: block;
                width: auto;
                padding: 0;
                border: none;
                &:last-child{
                    padding-right: 0;
                }   
            }
            .menu > li {
                padding: 1.25rem;
                position: relative;
                a {
                    text-decoration: none;
                    color: #ffffff;
                }
            }
        }
    }
}

