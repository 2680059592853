@import "https://use.fontawesome.com/ede1f9233a.css";
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@700&display=swap');

@import "variables";
@import '_components/_basic';
@import '_components/_header';
@import '_components/_mmenu';
@import '_components/_footer';
@import '_responsive/medium';
@import '_responsive/large';